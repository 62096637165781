import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

import { UqAppBarV2, UqLayoutV2, UqNavbarV2, UqFooterV2 } from '@uq-components';
import UqLayoutLegalPage from '../components/uqLayoutLegalPage';

import * as styles from './legal.module.scss';

export default function LegalCopyrightPage() {
  return (
    <UqLayoutV2>
      <UqAppBarV2
        className={styles.appbar}
        static
      >
        <UqNavbarV2 />
      </UqAppBarV2>

      <div className={styles.root}>
        <div className="master-container legal-copyright-page">
          <UqLayoutLegalPage
            pageInfo={{
              pageName: 'Legal',
              headerTitle: 'Legal',
              headerSubtitle: '',
              headerInfo: '',
              active: 'copyright',
            }}
            headerRowClass="legal-page"
          >
            <Container className="text-center content-wrapper">
              <Row className="mt-5 mb-4">
                <Col className="layout-col align-center">
                  <div className="legal-content">
                    <p className="c0">
                      <span className="c1">Effective date: November 12th 2018</span>
                    </p>
                    <p className="c0">
                      <span className="c1">
                        In accordance with the DMCA, we&rsquo;ve adopted the policy
                        below toward copyright infringement. We reserve the right to
                        (1) block access to or remove material that we believe in good
                        faith to be copyrighted material that has been illegally
                        copied and distributed by any of our advertisers, affiliates,
                        content providers, members or users and (2) remove and
                        discontinue service to repeat offenders.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c3">
                        Remember that your use of unitQ&rsquo;s Services is at all
                        times subject to the{' '}
                      </span>
                      <span className="c7">
                        <a className="c4" target="_blank" href="/legal-terms">
                          Terms of Use
                        </a>
                      </span>
                      <span className="c3">
                        , which incorporates this Copyright Dispute Policy. Any terms
                        we use in this Policy without defining them have the
                        definitions given to them in the{' '}
                      </span>
                      <span className="c7">
                        <a className="c4" target="_blank" href="/legal-terms">
                          Terms of Use
                        </a>
                      </span>
                      <span className="c1">.</span>
                    </p>
                    <p className="c0">
                      <span className="c3">(1) </span>
                      <b>
                        <i>Procedure for Reporting Copyright Infringements</i>
                      </b>
                      <span className="c1">
                        . If you believe that material or content residing on or
                        accessible through the Services infringes your copyright (or
                        the copyright of someone whom you are authorized to act on
                        behalf of), please send a notice of copyright infringement
                        containing the following information to the unitQ&rsquo;s
                        Designated Agent to Receive Notification of Claimed
                        Infringement (our &ldquo;Designated Agent,&rdquo; whose
                        contact details are listed below):
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1">
                        (a) A physical or electronic signature of a person authorized
                        to act on behalf of the owner of the copyright that has been
                        allegedly infringed;
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1">
                        (b) Identification of works or materials being infringed;
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1">
                        (c) Identification of the material that is claimed to be
                        infringing including information regarding the location of the
                        infringing materials that the copyright owner seeks to have
                        removed, with sufficient detail so that unitQ is capable of
                        finding and verifying its existence;
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1">
                        (d) Contact information about the notifier including address,
                        telephone number and, if available, email address;
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1">
                        (e) A statement that the notifier has a good faith belief that
                        the material identified in (1)(c) is not authorized by the
                        copyright owner, its agent, or the law; and
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1">
                        (f) A statement made under penalty of perjury that the
                        information provided is accurate and the notifying party is
                        authorized to make the complaint on behalf of the copyright
                        owner.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c3">(2) </span>
                      <b>
                        <i>
                          Once Proper Bona Fide Infringement Notification is Received
                          by the Designated Agent.{' '}
                        </i>
                      </b>
                      <span className="c1">
                        Upon receipt of a proper notice of copyright infringement, we
                        reserve the right to:
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1">
                        (a) remove or disable access to the infringing material;
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1">
                        (b) notify the content provider who is accused of infringement
                        that we have removed or disabled access to the applicable
                        material; and
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1">
                        (c) terminate such content provider&rsquo;s access to the
                        Services if he or she is a repeat offender.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c3">(3) </span>
                      <b>
                        <i>
                          Procedure to Supply a Counter-Notice to the Designated
                          Agent.
                        </i>
                      </b>
                      <span className="c1">
                        &nbsp;If the content provider believes that the material that
                        was removed (or to which access was disabled) is not
                        infringing, or the content provider believes that it has the
                        right to post and use such material from the copyright owner,
                        the copyright owner&rsquo;s agent, or, pursuant to the law,
                        the content provider may send us a counter-notice containing
                        the following information to the Designated Agent:
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1">
                        (a) A physical or electronic signature of the content
                        provider;
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1">
                        (b) Identification of the material that has been removed or to
                        which access has been disabled and the location at which the
                        material appeared before it was removed or disabled;
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1">
                        (c) A statement that the content provider has a good faith
                        belief that the material was removed or disabled as a result
                        of mistake or misidentification of the material; and
                      </span>
                    </p>
                    <p className="c0 c2">
                      <span className="c1">
                        (d) Content provider&rsquo;s name, address, telephone number,
                        and, if available, email address, and a statement that such
                        person or entity consents to the jurisdiction of the Federal
                        Court for the judicial district in which the content
                        provider&rsquo;s address is located, or, if the content
                        provider&rsquo;s address is located outside the United States,
                        for any judicial district in which unitQ is located, and that
                        such person or entity will accept service of process from the
                        person who provided notification of the alleged infringement.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c1">
                        If a counter-notice is received by the Designated Agent, unitQ
                        may, in its discretion, send a copy of the counter-notice to
                        the original complaining party informing that person that
                        unitQ may replace the removed material or cease disabling it
                        in 10 business days. Unless the copyright owner files an
                        action seeking a court order against the content provider
                        accused of committing infringement, the removed material may
                        be replaced or access to it restored in 10 to 14 business days
                        or more after receipt of the counter-notice, at unitQ&rsquo;s
                        discretion.
                      </span>
                    </p>
                    <p className="c0">
                      <span>&nbsp;</span>
                    </p>
                    <h1>
                      Please contact unitQ&rsquo;s Designated Agent at the following
                      address:
                    </h1>

                    <p className="c0">
                      <span>Copyright Dispute Agent:</span>
                    </p>
                    <p className="c0">
                      <span>unitQ inc.</span>
                    </p>
                    <p className="c0">
                      <span>
                        Email: <a href="mailto:help@unitq.com">help@unitq.com</a>
                      </span>
                    </p>
                    <p className="c10">
                      <span></span>
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </UqLayoutLegalPage>
        </div>
      </div>

      <UqFooterV2 />
    </UqLayoutV2>
  );
}
